"use strict";

import { EditorLoader } from "./editor_loader";

EditorLoader.extend("highligh_rules", function () {
  define("ace/mode/ejson_highlight_rules", function (require, exports, module) {
    "use strict";

    var oop = require("ace/lib/oop");
    var TextHighlightRules =
      require("ace/mode/text_highlight_rules").TextHighlightRules;
    var identifierRe = "[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*";

    var EJsonHighlightRules = function (options) {
      // regexp must not have capturing parentheses. Use (?:) instead.
      // regexps are ordered -> the first match is used
      this.$rules = {
        start: [
          {
            token: "keyword",
            regex:
              "BinData|new\\s+Date|ISODate|Timestamp|ObjectId|DBRef|MinKey|MaxKey|NumberLong|NumberDecimal|undefined",
          },
          {
            token: "meta", // $keyword single line
            regex: '["]\\$(?:(?:\\\\.)|(?:[^"\\\\]))*?["]\\s*(?=:)',
          },
          {
            token: "meta", // $keyword single line without quotes
            regex: '\\$(?:(?:\\\\.)|(?:[^"\\\\]))*?\\s*(?=:)',
          },
          {
            token: "variable", // single line
            regex: '["](?!$)(?:(?:\\\\.)|(?:[^"\\\\]))*?["]\\s*(?=:)',
          },
          {
            token: "string", // single line
            regex: '"',
            next: "string",
          },
          {
            token: "constant.numeric", // hex
            regex: "0[xX][0-9a-fA-F]+\\b",
          },
          {
            token: "constant.numeric", // float
            regex: "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b",
          },
          {
            token: "constant.language.boolean",
            regex: "(?:true|false)\\b",
          },
          {
            token: "string", // single quoted strings are not allowed
            regex: "['](?:(?:\\\\.)|(?:[^'\\\\]))*?[']",
          },
          {
            token: "comment", // comments are not allowed
            regex: "\\/\\/.*$",
          },
          {
            token: "comment.start", // comments are not allowed
            regex: "\\/\\*",
            next: "comment",
          },
          {
            token: "paren.lparen",
            regex: "[[({]",
          },
          {
            token: "paren.rparen",
            regex: "[\\])}]",
          },
          {
            token: "text",
            regex: "\\s+",
          },
        ],
        string: [
          {
            token: "constant.language.escape",
            regex: /\\(?:x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4}|["\\\/bfnrt])/,
          },
          {
            token: "string",
            regex: '"|$',
            next: "start",
          },
          {
            defaultToken: "string",
          },
        ],
        comment: [
          {
            token: "comment.end", // comments are not allowed
            regex: "\\*\\/",
            next: "start",
          },
          {
            defaultToken: "comment",
          },
        ],
      };
    };

    oop.inherits(EJsonHighlightRules, TextHighlightRules);
    exports.EJsonHighlightRules = EJsonHighlightRules;
  });
});
