let EditorLoader = {
  extensions: {},

  init: function () {
    window.addEventListener("load", EditorLoader.onLoad);
  },

  onLoad: function () {
    console.log("EditorLoader.onLoad");
    EditorLoader.entryPoint();
  },

  extend: (extensionName, extension) => {
    console.log(`Adding extension: ${extensionName}`);

    EditorLoader.extensions[extensionName] = extension;
  },

  loadExtensions: function () {
    Object.entries(EditorLoader.extensions).forEach((entry) => {
      console.log(`Loading extension: ${entry[0]}`);

      let extensionFunc = entry[1];

      extensionFunc();
    });
  },
};

export { EditorLoader };
