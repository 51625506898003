"use strict";

import { EditorLoader } from "./editor_loader";

EditorLoader.extend("mode", function () {
  define("ace/mode/ejson", [], function (require, exports, module) {
    "use strict";

    var oop = require("ace/lib/oop");
    var JsonMode = require("ace/mode/json").Mode;
    var HighlightRules =
      require("ace/mode/ejson_highlight_rules").EJsonHighlightRules;
    var MatchingBraceOutdent =
      require("ace/mode/matching_brace_outdent").MatchingBraceOutdent;
    var CstyleBehaviour = require("ace/mode/behaviour/cstyle").CstyleBehaviour;
    var CStyleFoldMode = require("ace/mode/folding/cstyle").FoldMode;

    var Mode = function () {
      this.HighlightRules = HighlightRules;
      this.$outdent = new MatchingBraceOutdent();
      this.$behaviour = new CstyleBehaviour();
      this.foldingRules = new CStyleFoldMode();
    };
    oop.inherits(Mode, JsonMode);

    exports.Mode = Mode;
  });
});
