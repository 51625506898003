// Using ES6 import syntax
import hljs from "highlight.js/lib/core";

hljs.registerLanguage("extjson", function (hljs) {
  var LITERALS = {
    literal: "true false null",
    keyword:
      "BinData new Date ISODate Timestamp ObjectId DBRef MinKey MaxKey NumberLong NumberDecimal undefined",
  };
  var TYPES = [
    hljs.APOS_STRING_MODE,
    hljs.QUOTE_STRING_MODE,
    hljs.C_NUMBER_MODE,
  ];
  var VALUE_CONTAINER = {
    end: ",",
    endsWithParent: true,
    excludeEnd: true,
    contains: TYPES,
    keywords: LITERALS,
  };
  var OBJECT = {
    begin: "{",
    end: "}",
    contains: [
      {
        className: "attr",
        begin: /"(?!\$)/,
        end: /"/,
        contains: [hljs.BACKSLASH_ESCAPE],
        illegal: "\\n",
      },
      {
        className: "meta",
        begin: /"\$/,
        end: /"/,
        contains: [hljs.BACKSLASH_ESCAPE],
        illegal: "\\n",
      },
      hljs.inherit(VALUE_CONTAINER, { begin: /:/ }),
    ],
    illegal: "\\S",
  };
  var ARRAY = {
    begin: "\\[",
    end: "\\]",
    contains: [hljs.inherit(VALUE_CONTAINER)], // inherit is a workaround for a bug that makes shared modes with endsWithParent compile only the ending of one of the parents
    illegal: "\\S",
  };
  TYPES.splice(TYPES.length, 0, OBJECT, ARRAY);
  return {
    contains: TYPES,
    keywords: LITERALS,
    illegal: "\\S",
  };
});

document.querySelectorAll("pre code").forEach(function (block) {
  const language = "extjson";
  const ignoreIllegals = true;

  hljs.highlight(block.textContent, { language, ignoreIllegals });
  hljs.highlightElement(block);
});

hljs.configure({ useBR: false });

document.querySelectorAll("code.inline").forEach(function (block) {
  hljs.highlightElement(block);
});
