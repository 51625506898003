window.addEventListener('load', function () {
  'use strict'

  Split(['#panel-query', '#panel-data'], {
    sizes: [70, 30],
    gutterSize: 5,
    cursor: 'pointer',
    direction: 'vertical',
    elementStyle: function (dimension, size, gutterSize) {
      return {
        height: 'calc(' + size + '% - ' + gutterSize + 'px - 30px)'
      }
    },
    onDragEnd: function (sizes) {
      var event = new Event('verticalResize')
      window.dispatchEvent(event)
    }
  })

  Split(['#panel-input', '#panel-output'], {
    sizes: [50, 50],
    gutterSize: 5,
    cursor: 'row-resize',
    onDragEnd: function (sizes) {
      var event = new Event('horizontalResize')
      window.dispatchEvent(event)
    }
  })
})
