"use strict";
import { EditorLoader } from "./editor_loader";

import snippet from "./ace.ejson.aggregate.snippets?raw";

EditorLoader.extend("snippet.aggregate", function () {
  define("ace/snippets/ejson.aggregate", function (require, exports, module) {
    "use strict";

    exports.snippetText = snippet;
    exports.scope = "ejson";
  });
});
