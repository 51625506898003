"use strict";

function showTooltip(element, text, timeout) {
  element.setAttribute("data-balloon", text);

  // Auto hide
  setTimeout(function () {
    element.removeAttribute("data-balloon");
    element.removeAttribute("data-balloon-visible");
  }, timeout);
}

window.addEventListener("load", function () {
  let className = ".copy-btn";
  let clipboard = new ClipboardJS(className);
  let buttons = document.querySelectorAll(className);

  let suppressClick = function (event) {
    event.preventDefault();
  };

  buttons.forEach(function (element) {
    element.addEventListener("click", suppressClick);
  });

  clipboard.on("success", function (e) {
    e.clearSelection();

    showTooltip(e.trigger, "Link copied!", 2000);
  });

  clipboard.on("error", function (e) {
    showTooltip(e.trigger, "Copy failed :(", 2000);
  });
});
