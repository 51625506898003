"use strict";
import { EditorLoader } from "./editor_loader";
import "./ace.ejson.aggregate.snippets.js";
import "./ace.ejson.snippets.js";

import "./ace.ejson.autocomplete";
import "./ace.ejson.highlight";
import "./ace.ejson.mode";

import ExtendedJSON from "../ejson/ejson.js";

EditorLoader.entryPoint = function () {
  let submitState = {
    "query-editor": false,
    "data-editor": false,
  };

  // Disable or enable submit depending on validation state
  document.addEventListener("ejson", function (event) {
    let result = event.detail;
    let submit = document.querySelector("input[type='submit']");

    submitState[result.selector] = result.success;
    let states = Object.values(submitState);

    if (states.includes(false)) {
      submit.disabled = true;
    } else {
      submit.disabled = false;
    }
  });

  // Add validation errors as annotations
  document.addEventListener("ejson", function (event) {
    var errors = [];
    var result = event.detail;

    if (result.success != true) {
      var pos = result.session.doc.indexToPosition(result.offset);

      errors.push({
        row: pos.row,
        column: pos.column,
        text: result.error,
        type: "error",
      });
    }

    result.session.setAnnotations(errors);
  });

  function debounce(fn, delay) {
    var timer = null;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  function validateEJSON(currentSession, selector) {
    let value = currentSession.getValue();

    if (value === "") {
      value = " ";
    }

    var result = ExtendedJSON.validate(value);

    var eventDetail = {
      session: currentSession,
      selector: selector,
    };

    if (result == true) {
      eventDetail.success = true;
    } else {
      eventDetail.success = false;
      eventDetail.error = result.error;
      eventDetail.offset = result.offset;
    }

    var event = new CustomEvent("ejson", {
      bubbles: true,
      detail: eventDetail,
    });

    document.dispatchEvent(event);
  }

  function findAndEnableSnippets(targetEditor) {
    let element = document.querySelector("[data-editor-snippets]");
    if (!element) {
      return;
    }

    let snippetNames = (element.dataset.editorSnippets || "").split(",");
    snippetNames.forEach(function (snippetName) {
      enableSnippet(targetEditor, snippetName);
    });
  }

  function enableSnippet(targetEditor, snippetName) {
    ace.config.loadModule("ace/snippets", function (module) {
      var snippetManager = module.snippetManager;
      var aggregateSnippets = ace.require(`ace/snippets/ejson.${snippetName}`);

      var id = targetEditor.session.$mode.$id || "";
      var m = snippetManager.files[id];

      m.scope = aggregateSnippets.scope;
      m.snippetText = aggregateSnippets.snippetText;
      m.snippet = snippetManager.parseSnippetFile(m.snippetText, m.scope);

      snippetManager.register(m.snippet, m.scope);
    });
  }

  EditorLoader.loadExtensions();

  let languageTools = ace.require("ace/ext/language_tools");
  let ejsonCompleter = ace.require("ace/autocomplete/ejson_completer");
  ace.require("ace/mode/json");

  languageTools.setCompleters([
    languageTools.snippetCompleter,
    ejsonCompleter,
    languageTools.keyWordCompleter,
  ]);

  let options = {
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
    minLines: 10,
    theme: "ace/theme/xcode",
    mode: "ace/mode/ejson",
    fontSize: "14px",
    fontFamily: "monospace",
    useWorker: false,
    tabSize: 2,
    useSoftTabs: true,
  };

  var editorUnloaded = false;

  function ignoreExitOnSubmit() {
    let form = document.querySelector("form");

    if (!form) {
      return;
    }

    form.addEventListener("submit", function (event) {
      editorUnloaded = true;
    });
  }

  function editorsChanged(editors) {
    var changed = false;

    editors.forEach(function (editor) {
      let undo = editor.session.getUndoManager();

      if (undo.hasUndo()) {
        changed = true;
      }
    });

    return changed;
  }

  function bindEditorsToExitEvent(...editors) {
    // Works in Chrome & Safari on desktop
    window.addEventListener("beforeunload", function (event) {
      if (editorUnloaded) return;

      if (editorsChanged(editors)) {
        editorUnloaded = true;
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Do you really want to leave?";
      }
    });
  }

  function bindEditorToTextArea(editor, selector) {
    let textarea = document.querySelector(`[data-editor="${selector}"]`);
    let placeholder = `[data-placeholder="${selector}"]`;

    if (!textarea) {
      return;
    }

    textarea.style.display = "none";

    if (!textarea.value) {
      textarea.value = document.querySelector(placeholder).textContent;
    }

    let currentSession = editor.getSession();

    currentSession.setValue(textarea.value);
    validateEJSON(currentSession, selector);

    currentSession.on("change", function () {
      textarea.value = editor.getSession().getValue();
    });

    currentSession.on(
      "change",
      debounce(function () {
        validateEJSON(currentSession, selector);
      }, 200)
    );
  }

  let editorForm = document.querySelector("form");
  let queryEditor = ace.edit("query-editor", options);
  let dataEditor = ace.edit("data-editor", options);

  bindEditorToTextArea(queryEditor, "query-editor");
  bindEditorToTextArea(dataEditor, "data-editor");

  window.addEventListener("verticalResize", function (event) {
    console.log("Resized vertical!");
    dataEditor.resize();
  });

  window.addEventListener("horizontalResize", function (event) {
    console.log("Resized horizontal!");
    queryEditor.resize();
  });

  let saveCommand = {
    name: "Save",
    bindKey: {
      win: "Ctrl-Enter",
      mac: "Command-Enter",
    },
    exec: function () {
      // Because `submit()` does not emit `onSubmit` event
      editorUnloaded = true;
      editorForm.submit();
    },
  };

  queryEditor.commands.addCommand(saveCommand);
  dataEditor.commands.addCommand(saveCommand);
  findAndEnableSnippets(queryEditor);

  bindEditorsToExitEvent(queryEditor, dataEditor);
  ignoreExitOnSubmit();
};
