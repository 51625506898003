"use strict";

import ExtendedJSON from "./ejson.js";
import ExtendedJSONHandler from "./ejson.handler";

let formatExpectation = function (expected) {
  var message = "Expected: " + expected.join(", ") + "\n";

  return message;
};

ExtendedJSON.Parser.prototype.validate = function () {
  var tree = this._read_root();

  if (
    tree !== ExtendedJSON.Parser.FAILURE &&
    this._offset === this._inputSize
  ) {
    return true;
  }

  if (this._expected.length === 0) {
    this._failure = this._offset;
    this._expected.push("<EOF>");
  }

  let errorMessage = formatExpectation(this._expected);

  return {
    error: errorMessage,
    offset: this._failure,
  };
};

ExtendedJSON.validate = function (input, options) {
  options = options || { actions: ExtendedJSONHandler };
  var parser = new ExtendedJSON.Parser(input, options.actions, options.types);
  return parser.validate();
};
