"use strict";

let ExtendedJSONHandler = {
  make_root: function (input, start, end, elements) {
    return elements[0];
  },

  make_object: function (input, start, end, elements) {
    let firstPair = elements[0];
    let otherPairs = elements[1];
    let object = {};

    object[firstPair.key] = firstPair.value;

    otherPairs.forEach(function (element) {
      let pair = element.pair;
      object[pair.key] = pair.value;
    });

    return object;
  },

  make_pair: function (input, start, end, elements) {
    return { key: elements[0], value: elements[1] };
  },

  make_empty_object: function (input, start, end, elements) {
    return {};
  },

  make_string: function (input, start, end, elements) {
    let text = elements[0].text;
    return `${text}`;
  },

  make_identifier: function (input, start, end, elements) {
    let name = input.slice(start, end);

    return name;
  },

  make_array: function (input, start, end, elements) {
    let list = [elements[0]];

    elements[1].forEach(function (element) {
      list.push(element.value);
    });

    return list;
  },

  make_empty_array: function (input, start, end, elements) {
    return [];
  },

  make_number: function (input, start, end, elements) {
    let number = input.slice(start, end);

    return Number(number);
  },

  make_numeric_string: function (input, start, end, elements) {
    return elements[0];
  },

  make_number_as_string: function (input, start, end, elements) {
    let number = input.slice(start, end);

    return number;
  },

  make_null: function (input, start, end) {
    return null;
  },

  make_true: function (input, start, end) {
    return true;
  },

  make_false: function (input, start, end) {
    return false;
  },

  make_object_id: function (input, start, end, elements) {
    return { $oid: elements[0] };
  },

  make_bin_data: function (input, start, end, elements) {
    return { $binary: elements[1], $type: `${elements[0]}` };
  },

  make_timestamp: function (input, start, end, elements) {
    return { $timestamp: { t: elements[0], i: elements[1] } };
  },

  make_number_long: function (input, start, end, elements) {
    return { $numberLong: `${elements[0]}` };
  },

  make_number_decimal: function (input, start, end, elements) {
    return { $numberDecimal: `${elements[0]}` };
  },

  make_date: function (input, start, end, elements) {
    return { $date: `${elements[0]}` };
  },

  make_regexp: function (input, start, end, elements) {
    return { $regex: `${elements[0].text}`, $options: elements[1].text };
  },

  make_db_ref: function (input, start, end, elements) {
    return { $ref: `${elements[0]}`, $id: `${elements[1]}` };
  },

  make_min_key: function (input, start, end) {
    return { $minKey: 1 };
  },

  make_max_key: function (input, start, end) {
    return { $maxKey: 1 };
  },

  make_undefined: function (input, start, end) {
    return { $undefined: true };
  },
};

export default ExtendedJSONHandler;
